export const LocalConst = {
    token: 'token',
    defaultTheme: '20ade5',
    ysClient: "ysClient",
    lang: 'lang',//多语言
    currencySymbol: 'currencySymbol',// 货币符号
    mShop_user: "mShop_user",

    wechatData: "wechatData",
    companyId: "companyId",// 公司id
    companyName: "companyName",// 公司id
    companyData: "companyData",// 公司信息
    companyTheme: "companyTheme",// 公司主题色
    authCode: "authCode",// 登陆标识
    accountList: "accountList",// 账号列表
    prevLocation: "prevLocation",//上次路由
    localHistory: "localHistory",//本地history
    cartCount: "cartCount",//购物车数量
    floorData: "floorData",// 自定义装修页面数据
    indexList: "indexList",//首页列表
    homePageName: "homePageName",//装修页标题
    shopGoodsList: "shopGoodsList",// 订货端商品列表
    shopGoodsListLoaded: "shopGoodsListLoaded",//标记订货端商品列表页初次加载完成
    shopIndexAnchor: "shopIndexAnchor",//首页滚动位置
    shopPageCache: "shopPageCache",//首页缓存数据
    subsidiaryId:"subsidiaryId",//分仓ID

    goodsListAnchor: "goodsListAnchor",//商品列表页面滚动位置
    goodsListParams: "goodsListParams",//商品列表页面筛选条件
    goodsListSlideAnchor: "goodsListSlideAnchor",//商品列表分类滚动位置
    orderListParams: 'orderListParams',// 订单列表筛选条件

    classifyListAnchor: "classifyListAnchor",//分类列表页面滚动位置
    classifyListParams: " classifyListParams",//分类列表页面筛选条件
    classifyListSlideAnchor: "classifyListSlideAnchor",//分类列表分类滚动位置

    imgClassifySlideAnchor: "imgClassifySlideAnchor",//商品列表生鲜农贸模式下一级分类滚动位置
    selectClassifyInfo: 'selectClassifyInfo',//商品列表生鲜农贸模式记录选择分类

    goodsListLabelAnchor: "goodsListLabelAnchor",//商品标签页面滚动位置
    goodsListLabelParams: "goodsListLabelParams",//商品标签页面筛选条件
    goodsListLabelTitle: "goodsListLabelTitle",//商品标签页面标题
    goodsListLabelQuery: "goodsListLabelQuery",//商品标签页面url条件

    goodsSearchResultAnchor: "goodsSearchResultAnchor",//搜索结果页滚动位置
    goodsSearchResultParams: "goodsSearchResultParams",//搜索结果页筛选条件

    mgrOrderListAnchor: "mgrOrderListAnchor",//管理端订单列表锚点
    mgrOrderListParams: "mgrOrderListParams",//管理端订单列表筛选条件

    isLogin: "isLogin",// 是否登陆
    isExperience: "isExperience",//是否体验账号
    enableMinCount: "enableMinCount",// 起订量
    enableMaxCount: "enableMaxCount",// 限订量
    brandList: "brandList",// 商品品牌列表
    styleList: "styleList",// 商品风格列表
    typeList: "typeList",// 商品分类列表
    groupClassifyList: 'groupClassifyList', //组合分类
    tagList: "tagList",// 商品标签列表
    propertyTree: "propertyTree",// 商品属性列表
    priceScale: "priceScale",// 商品价格精度
    countScale: "countScale",// 商品数量精度

    enableStyle: "enableStyle",// 是否开启风格筛选
    enableBrand: "enableBrand",// 是否开启品牌筛选

    stockShowType: "stockShowType",// 库存展示方式
    enableStockZero: "enableStockZero",// 是否允许超售
    enableExpectTime: "enableExpectTime",// 是否允许超期
    enableOrderTotal: "enableOrderTotal",// 是否开启下单金额限制
    orderTotal: "orderTotal",// 下单金额限制

    enableTax: "enableTax",// 是否开启发票
    containTax: "containTax",// 商品是否含税
    generalTax: "generalTax", //普通发票税率
    addedTax: "addedTax",//增值税发票税率
    otherTax: "otherTax",//其他发票
    taxType: "taxType",//发票类型
    defaultTaxType: "defaultTaxType",//默认发票类型
    enableSpecialTotal: "enableSpecialTotal",
    simpleRegionTree:"simpleRegionTree",//地址信息
    orderDefaultUnit: "orderDefaultUnit",

    allowVisitor: "allowVisitor",// 游客是否允许访问
    allowClientPrice: "allowClientPrice",// 游客是否允许查看价格
    showGoodsType: "showGoodsType",// 商品列表展示模式
    showGoodsUnit: "showGoodsUnit",// 是否显示单位
    shopHomeTitle: "shopHomeTitle",// 商品列表展示模式
    allowClientRegister: "allowClientRegister", // 是否允许游客注册
    defaultShipWay: "defaultShipWay",// 支付方式
    shopSetting: "shopSetting",// 商城设置

    defaultHomePageBannerList: "defaultHomePageBannerList",
    // 管理端
    mgrGoodsList: "mgrGoodsList",
    mgrGoodsListAnchor: "mgrGoodsListAnchor",//商品列表页面滚动位置
    mgrGoodsListParams: "mgrGoodsListParams",//商品列表页面筛选条件

    shareDefaultLogo: "http://timg.qipeidd.com/shareDefaultLogo.png",  //分享默认图片

    shareUserId: "shareUserId",// 分享人id
    shareClientId: "shareClientId",// 分享人id
    refundProduct: "refundProduct",// 退货商品
    refundList: "refundList",// 退货单
    refundGoodsList: "refundGoodsList",// 退货商品
    enableSaleBackOrder: "enableSaleBackOrder",// 仅允许订单退货
    enableShopNoOrder: "enableShopNoOrder",// 是否开启禁止下单
    shopNoOrderTime: "shopNoOrderTime",// 禁止时间
    anotherCart: "anotherCart",// 再来一单
    shopCartList: "shopCartList",// 购物车列表

    houseSetting: "houseSetting", // 仓库设置
    msgGoods: "msgGoods",//咨询商品
    isShowBindTips: "isShowBindTips",//是否显示个人中心页面绑定手机提示
    isLighting: "isLighting",//是否是灯饰行业
    wechatBind: "wechatBind",// 3_1_9:是否微信绑定

    enableRebateLevel: "enableRebateLevel",//分销等级
    distributionModelTitle: 'distributionModelTitle',// 分销标题
    distributionJobName: 'distributionJobName', // 分销人职位
    posterBackground: 'posterBackground', // 推广海报背景图
    invitationCardBackground: 'invitationCardBackground', // 邀请卡背景图
    joiningInstructions: 'joiningInstructions',//加盟说明

    navBarDecorate: 'navigationBarDecorate',//导航应用页面
    navBarJson: 'navBarJson',//导航装修json数据
    navDecoratePage: 'navDecoratePage', //应用导航页
    barPathname: 'barPathname',//导航路由
    floatingWindowDecorate: "floatingWindowDecorate",//悬浮窗装修页面
    floatingWindowJson: "floatingWindowJson",//悬浮窗装修数据
    publicAdDecorate: "publicAdDecorate",//公共广告应用页面
    publicAdJson: "publicAdJson",//公共广告装修数据
    publicAdPosition: "publicAdPosition",//公共广告位置
    goodsListSetting: "goodsListSetting",//商品列表设置
    goodsTypeSetting: "goodsTypeSetting",//商品分类设置
    topGoodsTypeNode: "topGoodsTypeNode",//首个分类
    enablePcScene: "enablePcScene",//是否开启了PC显示去配灯按钮
    MineSetting: "MineSetting",// 个人中心设置
    BaseSetting: "BaseSetting",// 基础设置

    bindWay: 'bindWay',//上下级绑定方式
    parentClientId: 'parentClientId', //上级客户ID

    generalizeGoodsAnchor: 'generalizeGoodsAnchor', // 推广商品滚动条位置
    generalizeGoodsParams: 'generalizeGoodsParams', //推广商品页面参数位置
    companyOpenScore: 'companyOpenScore',//积分商城权限
    companyEnableSign: 'companyEnableSign',//积分商城签到权限

    applicationStatus: "applicationStatus",//应用状态

    isClientChildAccount: "isClientChildAccount", //是否是员工
    clientAccountType: "clientAccountType", //员工类型
    isOrdinaryEmployees: "isOrdinaryEmployees", //是否是员工普通员工

    carQueryLog:'carQueryLog',// 车型查询记录
    additionalFeeSetting:'additionalFeeSetting',// 附加费用
    deliveryPlaceList: 'deliveryPlaceList',
    splitPropertyList: 'splitPropertyList',
    splitPropertyRule: 'splitPropertyRule',
    splitPropertyTree: 'splitPropertyTree',

    isChildAccount: 'isChildAccount',  // 子账号
    childAccountRole : 'childAccountRole ',  // 子账号角色

    furnitureTypeIdList : 'furnitureTypeIdList ',



};
// 以下是sessionStorage
export const SessionConst = {
    prevLocation: "prevLocation", //记录上一个路由
    localHistory: "localHistory", //本地路由History

    orderGuest: "orderGuest",//代客下单客户
    goodsSearchQuery: 'goodsSearchQuery', // 商品搜索条件
    goodsSearchResult: 'goodsSearchResult', // 商品搜索结果
    goodsSearchAnchor: 'goodsSearchAnchor', // 商品滚动位置
    followupUser: 'followupUser', // 跟进客户记录
};

// indexedDB缓存表
export const DBConst = {
    goodsList: 'goodsList',// 商品列表缓存
    goodsListLabel: 'goodsListLabel',// 分类列表缓存
    orderList: 'orderList',// 订单列表缓存
    cartList: 'cartList',// 购物车列表缓存
    generalizeGoodsList: 'generalizeGoodsList',// 推广商品列表
};
